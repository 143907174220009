import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { IStickerDraggable } from 'stores/stickerStore/interfaces';
import { DraggableContainer } from './styles';

import fitty from 'fitty';
import { useRootStore } from 'stores/StoreProvider';
import { EStickerProperty } from 'stores/stickerStore/constants';
import { ResizeIcon } from './components/ResizeIcon';
import { useAutoFontSize } from 'hooks/useAutoFontSize';

interface IDraggable {
  item: IStickerDraggable;
  onDragEnd: (x: number, y: number) => void;
  zoomLevel: number;
}

export const Draggable: React.FC<IDraggable> = observer(({ item, onDragEnd, zoomLevel }) => {
  const { stickerStore, boardStore, isDrawerOpen, setDrawerOpen } = useRootStore();

  const { isStickerDragging, setStickerDragging } = stickerStore;
  const { setIsStickerOverlapsMenu, setSticker } = stickerStore;
  const { boardConfigParsed } = boardStore;

  const { x, y, width, height, text, stickerColor, fontColor, fontSize: initialFontSize } = item;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const calculatedFontSize = useAutoFontSize(text, width, height);

  // Determine the font size to apply to the textarea
  // If text is empty, use a large size for the cursor, otherwise use the calculated size
  const displayFontSize = text ? calculatedFontSize : Math.max(16, height - 20); // Use large size when empty

  const [posX, setPosX] = useState(x);
  const [posY, setPosY] = useState(y);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);

  const [isResizing, setIsResizing] = useState(false);
  const [startWidth, setStartWidth] = useState(width);
  const [startHeight, setStartHeight] = useState(height);

  const isOverlappingMenu = (stickerRect: DOMRect, menuRect: DOMRect) => {
    return !(
      stickerRect.right < menuRect.left ||
      stickerRect.left > menuRect.right ||
      stickerRect.bottom < menuRect.top ||
      stickerRect.top > menuRect.bottom
    );
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setStartX(event.clientX / zoomLevel - posX);
    setStartY(event.clientY / zoomLevel - posY);
    setStickerDragging(true);
  };

  const handleResizeMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    setStartWidth(width);
    setStartHeight(height);
    setStartX(event.clientX);
    setStartY(event.clientY);
    setIsResizing(true);
  };

  useEffect(() => {
    if (!boardConfigParsed) return;

    const handleMouseMove = (event: MouseEvent) => {
      if (isResizing) {
        const newWidth = Math.min(
          boardConfigParsed.stickerMaxSize,
          Math.max(
            boardConfigParsed.stickerMinSize,
            startWidth + (event.clientX - startX),
          ),
        );
        const newHeight = Math.min(
          boardConfigParsed.stickerMaxSize,
          Math.max(
            boardConfigParsed.stickerMinSize,
            startHeight + (event.clientY - startY),
          ),
        );

        let adjustedPosX = posX;
        let adjustedPosY = posY;

        if (adjustedPosX + newWidth > boardConfigParsed.boardWidth) {
          adjustedPosX = boardConfigParsed.boardWidth - newWidth;
        }
        if (adjustedPosY + newHeight > boardConfigParsed.boardHeight) {
          adjustedPosY = boardConfigParsed.boardHeight - newHeight;
        }

        setPosX(Math.max(0, adjustedPosX));
        setPosY(Math.max(0, adjustedPosY));

        setSticker(EStickerProperty.Width, newWidth > 0 ? newWidth : 0);
        setSticker(EStickerProperty.Height, newHeight > 0 ? newHeight : 0);
      } else if (isStickerDragging) {
        let newX = event.clientX / zoomLevel - startX;
        let newY = event.clientY / zoomLevel - startY;

        newX = Math.max(0, Math.min(newX, boardConfigParsed.boardWidth - width));
        newY = Math.max(0, Math.min(newY, boardConfigParsed.boardHeight - height));

        setPosX(newX);
        setPosY(newY);

        const menu = document.getElementById('menu');
        if (menu) {
          const menuRect = menu.getBoundingClientRect();
          const stickerRect = {
            top: newY,
            left: newX,
            right: newX + width,
            bottom: newY + height,
          };

          const overlaps = isOverlappingMenu(stickerRect as DOMRect, menuRect);

          if (overlaps && isDrawerOpen) {
            setDrawerOpen(false);
          } else if (
            !overlaps &&
            stickerRect.right < menuRect.left - menuRect.width * 1.5 &&
            !isDrawerOpen
          ) {
            setDrawerOpen(true);
          }
          //setIsStickerOverlapsMenu(overlaps);
        }
      }
    };

    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
      } else if (isStickerDragging) {
        onDragEnd(posX, posY);
        setStickerDragging(false);
        setIsStickerOverlapsMenu(false);
      }
    };

    if (isResizing || isStickerDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (isResizing || isStickerDragging) {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [
    isResizing,
    isStickerDragging,
    startX,
    startY,
    startWidth,
    startHeight,
    posX,
    posY,
    onDragEnd,
  ]);

  const handleTextChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSticker(EStickerProperty.Text, event.target.value);
    },
    [setSticker],
  );

  useEffect(() => {
    if (calculatedFontSize !== initialFontSize) {
      setSticker(EStickerProperty.FontSize, calculatedFontSize);
    }
  }, [calculatedFontSize, initialFontSize, setSticker]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return (
    <DraggableContainer
      onMouseDown={handleMouseDown}
      className='draggable-sticker pulsate'
      isStickerDragging={isStickerDragging}
      style={{
        left: `${posX}px`,
        top: `${posY}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: stickerColor,
        color: fontColor,
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <textarea
        ref={textareaRef}
        value={text}
        onChange={handleTextChange}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          resize: 'none',
          color: 'inherit',
          // Apply displayFontSize which handles the initial large cursor case
          fontSize: `${displayFontSize}px`,
          fontFamily: 'inherit',
          textAlign: 'center',
          overflowWrap: 'break-word',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          lineHeight: '1.2',
        }}
        maxLength={80} // Set maxLength to 80
      />
      <ResizeIcon handleResizeMouseDown={handleResizeMouseDown} />
    </DraggableContainer>
  );
});
