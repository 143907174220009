import { makeAutoObservable } from 'mobx';
import { getStickerPrice, postSticker } from 'services/stickers';
import { IStickerDraggable } from './interfaces';
import { defaultStickerTemplate, EStickerProperty } from './constants';
import { RootStore } from 'stores/rootStore';
import { setErrorAlert, setSuccessAlert } from 'helpers/alertMessages';
import debounce from 'lodash/debounce';

export default class StickerStore {
  private rootStore: RootStore;

  isStickerDragging: boolean = false;
  sticker: IStickerDraggable | null = null;
  isStickerOverlapsMenu: boolean = false;
  stickerPrice: number = 0;

  isLoading: boolean = false;
  isStickerPriceLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  get boardConfig() {
    return this.rootStore.boardStore.boardConfigParsed;
  }

  initSticker = () => {
    this.setStickerToDefault();
  };

  setStickerDragging = (value: boolean) => {
    this.isStickerDragging = value;
  };

  setSticker = (key: EStickerProperty, value: string | number) => {
    // @ts-ignore
    this.sticker[key] = value;

    if (key === EStickerProperty.Height || key === EStickerProperty.Width) {
      this.debouncedCalculatePrice();
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setIsStickerPriceLoading = (value: boolean) => {
    this.isStickerPriceLoading = value;
  };

  setStickerPrice = (newPrice: number) => {
    this.stickerPrice = newPrice;
  };

  setIsStickerOverlapsMenu = (value: boolean) => {
    this.isStickerOverlapsMenu = value;
  };

  moveSticker = (x: number, y: number) => {
    if (!this.sticker) return;

    this.sticker.x = x;
    this.sticker.y = y;
  };

  handleCreateSticker = async () => {
    this.initSticker();
    await this.handleGetStickerPrice();
    this.rootStore.setDrawerOpen(true);
  };

  handleDeleteSticker = () => {
    this.sticker = null;
    this.rootStore.setDrawerOpen(false);
  };

  setStickerToDefault = () => {
    if (!this.boardConfig) return;

    this.sticker = {
      ...defaultStickerTemplate,
      width: this.boardConfig?.stickerDefaultSize,
      height: this.boardConfig?.stickerDefaultSize,
    };
  };

  onStickerCreateSuccess = () => {
    setSuccessAlert('Стикер опубликован!');
    this.rootStore.closeModal();
    this.handleDeleteSticker();
    this.rootStore.boardStore.fetchStickerBoard();
  };

  resetStickerStore = () => {
    this.sticker = null;
  };

  handlePostSticker = async () => {
    if (!this.sticker) return;

    this.setIsLoading(true);
    try {
      const params = {
        ...this.sticker,
        fontSize: this.sticker.fontSize || 16,
      };

      await postSticker(params);

      this.onStickerCreateSuccess();
    } catch (error: any) {
      console.error('Error posting sticker:', error.message);
      setErrorAlert(error.message);
    } finally {
      this.setIsLoading(false);
    }
  };

  handleGetStickerPrice = async () => {
    if (!this.sticker) return;

    this.setIsStickerPriceLoading(true);
    try {
      const params = { height: this.sticker.height, width: this.sticker.width };
      const { data } = await getStickerPrice(params);

      this.setStickerPrice(data);
    } catch (error) {
      console.error('Error fetching sticker price:', error);
    } finally {
      this.setIsStickerPriceLoading(false);
    }
  };

  debouncedCalculatePrice = debounce(() => {
    this.handleGetStickerPrice();
  }, 500);
}
