import React, { ChangeEvent, useCallback } from 'react';
import { useRootStore } from 'stores/StoreProvider';
import { observer } from 'mobx-react-lite';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import {
  CreateStickerFormContainer,
  DrawerContainer,
  FooterContainer,
  SubmitBtn,
} from './styles';
import { EStickerProperty } from 'stores/stickerStore/constants';
import { ColorPicker } from './components/ColorPicker';
import { RUB } from 'constants/main';
import { ConfirmationModal } from './components/ConfirmationModal';
import { TitleWithActions } from 'ui/TitleWithActions';

export const StickerCreateDrawer = observer(() => {
  const {
    stickerStore,
    isDrawerOpen,
    isPaymentModalOpen,
    setDrawerOpen,
    setPaymentModalOpen,
  } = useRootStore();

  const {
    sticker,
    isStickerOverlapsMenu,
    setSticker,
    setStickerToDefault,
    handleDeleteSticker,
    stickerPrice,
    isStickerPriceLoading,
  } = stickerStore;

  const handleChange = useCallback(
    (property: EStickerProperty, value: string | number) => {
      setSticker(property, value);
    },
    [setSticker],
  );

  if (!sticker) return null;

  return (
    <DrawerContainer id='menu' open={isDrawerOpen} $isHidden={isStickerOverlapsMenu}>
      <TitleWithActions
        title='Добавить новый стикер'
        onClick={() => setDrawerOpen(false)}
      />

      <CreateStickerFormContainer>
        <Typography variant='body1'>{`Высота: ${sticker.height}`}</Typography>
        <Typography variant='body1'>{`Ширина: ${sticker.width}`}</Typography>

        <ColorPicker
          label='Цвет фона'
          color={sticker.stickerColor}
          onChange={(newColor) => handleChange(EStickerProperty.StickerColor, newColor)}
        />
        <ColorPicker
          label='Цвет текста'
          color={sticker.fontColor}
          onChange={(newColor) => handleChange(EStickerProperty.FontColor, newColor)}
        />

        <div>
          <Button onClick={setStickerToDefault}>Сбросить значения</Button>
          <Button color='error' onClick={handleDeleteSticker}>
            Удалить стикер
          </Button>
        </div>
      </CreateStickerFormContainer>

      <FooterContainer>
        <Typography variant='body1'>
          {'Стоимость стикера: '}
          {isStickerPriceLoading ? (
            <CircularProgress size='18px' />
          ) : (
            <span>{`${stickerPrice} ${RUB}`}</span>
          )}
        </Typography>
        <SubmitBtn variant='contained' onClick={() => setPaymentModalOpen(true)}>
          Добавить стикер
        </SubmitBtn>
      </FooterContainer>

      <ConfirmationModal
        open={isPaymentModalOpen}
        handleClose={() => setPaymentModalOpen(false)}
      />
    </DrawerContainer>
  );
});
