import { useState, useEffect } from 'react';

const LINE_HEIGHT = 1.2; // Consistent with textarea style
const PADDING = 10; // Approximate padding within the sticker

export function useAutoFontSize(
  text: string,
  stickerWidth: number,
  stickerHeight: number,
): number {
  // Adjust effective dimensions by padding
  const effectiveWidth = Math.max(1, stickerWidth - PADDING * 2);
  const effectiveHeight = Math.max(1, stickerHeight - PADDING * 2);

  // Set initial fontSize to a reasonable default or estimate
  const [fontSize, setFontSize] = useState<number>(16);

  useEffect(() => {
    const calculateFontSize = () => {
      if (!text || effectiveWidth <= 0 || effectiveHeight <= 0) {
        setFontSize(16); // Reset to default if no text or invalid dimensions
        return;
      }

      let minFontSize = 1;
      // Set a more reasonable max font size initially
      let maxFontSize = Math.max(1, Math.floor(effectiveHeight / LINE_HEIGHT));
      let optimalFontSize = minFontSize; // Start with the smallest possible

      const context = document.createElement('canvas').getContext('2d');
      if (!context) return;

      // Helper function to calculate height with wrapping
      const getTextHeight = (currentFontSize: number): number => {
        context.font = `${currentFontSize}px Arial`; // Use Arial for measurement consistency
        const words = text.split(/\s+/);
        let line = '';
        let lines = 1;
        for (const word of words) {
          const testLine = line + word + ' ';
          const metrics = context.measureText(testLine);
          const testWidth = metrics.width;
          if (testWidth > effectiveWidth && line !== '') {
            lines++;
            line = word + ' ';
          } else {
            line = testLine;
          }
        }
        return lines * currentFontSize * LINE_HEIGHT;
      };

      // Binary search for optimal font size
      while (minFontSize <= maxFontSize) {
        const midFontSize = Math.floor((minFontSize + maxFontSize) / 2);
        if (midFontSize === 0) break; // Avoid infinite loop if mid becomes 0

        const currentTextHeight = getTextHeight(midFontSize);

        // Check if text fits within the effective height with a larger safety margin
        if (currentTextHeight <= effectiveHeight - 15) { // Increased safety margin to 15px
          optimalFontSize = midFontSize; // This size fits, try larger
          minFontSize = midFontSize + 1;
        } else {
          maxFontSize = midFontSize - 1; // This size is too large, try smaller
        }
      }
      // Ensure font size is at least 1
      setFontSize(Math.max(1, optimalFontSize));
    };

    calculateFontSize();
    // Depend on effective dimensions as well
  }, [text, effectiveWidth, effectiveHeight]);

  return fontSize;
}
